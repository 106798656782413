import { createApp } from 'vue';
import { JuouManageBaseUI, JuouManageBaseUIApp, JuouComponentsMap } from 'juou-managebase-ui';
import 'juou-managebase-ui/lib/lib.css';
import './style/global.scss';
import { initApi } from './hooks/useApi';
import NutUI from '@nutui/nutui';

const allComponents: JuouComponentsMap = {};
const viewRequireCnt = require.context('./views', true, /index\.vue$/u);
viewRequireCnt.keys().forEach((key) => {
  const keySplits = key.split('/');
  const dirname = keySplits[keySplits.length - 2];
  console.log(dirname);
  allComponents[dirname!] = () => viewRequireCnt(key).default || viewRequireCnt(key);
});

initApi('/api');

const app = createApp(JuouManageBaseUIApp)
  .use(JuouManageBaseUI, {
    siteName: '科技AI生活',
    baseApiPath: '/api',
    viewMap: allComponents,
    description: '扫码开门后台',
    logo: '/logo.png',
    themeOption: {
      primaryColor: '#1e4181',
      bgColor: '#ffffff',
      bgColorHover: '#1e4181',
      borderRadius: '4px',
    },
    titleAlwaysShowSiteName: false,
  })
  .use(NutUI);

app.mount('#app');


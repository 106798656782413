<template>
  <el-scrollbar  class="index-home">
    <img
      :src="bannerImg"
      class="radius" style="width: 100%; margin-bottom: 2px;"  />
    <div class="ad-box">
    </div>
    <nut-cell class="cell">
      <div class="open-key">
        <div class="open-key-bt" @click="onUnlock">
          <IconFont font-class-name="iconfont" class-prefix="icon" class="open-key-icon" :size="32" name="michi"
           />
           <div style="font-size: 14px;">点击开门</div>
        </div>

        <nut-button type='primary' @click="onUnlock" class="openbt">一键开门</nut-button>
      </div>
    </nut-cell>
    <div class="ad-box qrcode" style="height: 55vw;">
      <img :src="qrcodeImg" style="height: 70%; margin-bottom: 2px;" mode="widthFix" />
      <div class="qrcode-text">墨脱文旅</div>
    </div>
    <component style="height: 100vh; position: fixed;" :is="loading.cnt" />
    <nut-dialog title="需要授权" content="一键开门需要授权登录后才能试用，是否跳转授权？"
      v-model:visible="dialogVisible"
      @ok="handleToBindWx" />
    <nut-dialog title="申请开门"
      v-model:visible="applyDialogVisible"
      :before-close="handleApplyBeforeClose">
      <div>
        <p style="color: red; font-size: 14px;">本店已启动白名单，您并非本店白名单用户，开门需要向店主申请，是否申请？</p>
      </div>
    </nut-dialog>
    <component :is="loading.cnt" />
    <component :is="toast.cnt" />
  </el-scrollbar>
</template>

<script lang="ts" setup>
import bannerImg from './assets/banner.png';
import qrcodeImg from './assets/qrcode.jpg';
import { AxiosError } from 'axios';
import { ref } from 'vue';
import { useApi } from '@/hooks/useApi';
import { useLoading } from '@/hooks/useLoading';
import { useBaseUserStore, useRoute, useRouter } from 'juou-managebase-ui';
import { useToast } from '@/hooks/useToast';

const dialogVisible = ref(false);
const applyDialogVisible = ref(false);
const applyStoreId = ref<number>();

const loading = useLoading();
const toast = useToast();

const route = useRoute();
const router = useRouter();

const { query } = route;
const baseUserStore = useBaseUserStore();

const onUnlock = async() => {
  if (!query.code || !query.appid) {
    console.log(query);
    toast.show('请通过微信扫码开门');
    return;
  }

  if (!baseUserStore.token) {
    dialogVisible.value = true;
    return;
  }

  try {
    loading.show('开锁中...');
    const res = await useApi().storeApi.opendoorByCodestore({
      code: query.code as string,
    }, {
      headers: {
        appid: query.appid as string,
      },
    });
    console.log(res);
    router.push({
      name: 'success',
      state: {
        data: res?.data?.data,
      },
    });
  } catch (error: any) {
    if (!error) {
      return;
    }

    if (error instanceof AxiosError) {
      toast.show('开门失败');
      return;
    }

    console.log(error);

    // 需要申请
    if (error?.data?.needApplay && error?.data?.storeId) {
      applyStoreId.value = error?.data?.storeId;
      applyDialogVisible.value = true;
      return;
    }

    toast.show('开门失败!');
  } finally {
    loading.hide();
  }
};

const handleApplay = async() => {
  try {
    if (!applyStoreId.value || !query.appid) {
      toast.show('未获取到门店信息!');
      return;
    }
    loading.show('提交中...');
    await useApi().whiteAccountApi.applywhiteAccount(applyStoreId.value, {
      headers: {
        appid: query.appid as string,
      },
    });
    applyDialogVisible.value = false;
    toast.show('已提交申请!');
  } catch (error) {
    if (!error) {
      throw error;
    }
    toast.show('申请失败!');
    throw error;
  } finally {
    loading.hide();
  }
};

const handleApplyBeforeClose = async(event: any) => {
  console.log(event);
  if (event !== 'ok') {
    return true;
  }
  await handleApplay();
  return true;
};

const handleToBindWx = () => {
  router.push(`/bindwx?appid=${query.appid}&state=${encodeURI('授权成功，请重新扫码')}`);
};


const init = () => {
  if (!query.appid) {
    alert('请通过扫码进入');
    window.close();
    return;
  }
  onUnlock();
};

init();

</script>

<style lang="scss">
.index-home {
  padding: 16px;
  overflow-y: auto;
  box-sizing: border-box;

  .cell {
    margin: 4px 0;
  }

  .qrcode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    &-text {
      letter-spacing: 2px;
      font-size: 16px;
    }
  }


  .open-key {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      color: $primary-color;
      margin-bottom: 32px;
    }


    &-bt {
      margin-bottom: 36px;
      width: 120px;
      height: 120px;
      border-radius: 60px;
      display: flex;
      box-shadow: 0px 2px 10px rgba(0,0,0,0.4);
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      align-items: center;

      background-color: $primary-color;
      color: #fff !important;

    }

    .openbt {
      font-size: 18px;
      width: 100%;
      height: 42px;
      letter-spacing: 2px;
    }

  }


}
</style>

<template>
  <el-dialog v-model="qrcodeDialogVisible" :title="`${store?.name || ''} （一店一码）`" width="900px" :z-index="1000">
    <div class="qrcode">
      <div>
        <el-button type="primary" @click="() => addWeappFormRef?.showDialog()">添加小程序二维码</el-button>
        <el-button type="primary" @click="() => addH5FormRef?.showDialog()">添加H5二维码</el-button>
        <el-button type="primary" @click="() => handleAddDirectQrcode()">添加直开二维码</el-button>
      </div>
      <el-table v-loading="loading" :data="qrcodeValues" style="width: 100%">
        <el-table-column prop="appid" label="名称" width="180">
          <template #default="scope">
            <span>
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="二维码" width="180">
          <template #default="scope">
            <QrcodeVue :id="`qrcode-${scope.$index}`" :value="scope.row.url" :size="800"
              style="width: 100px; height: 100px" />
          </template>
        </el-table-column>
        <el-table-column label="客户端下载" >
          <template #default="scope">
            <span>{{ store.downloadQrcodeUrl === scope.row.url ? '是': '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label=""  width="380">
          <template #default="scope">
            <div style="display: flex; gap: 16px;">
              <el-button type="primary" @click="() => saveQrcode(scope.row, scope.$index)">下载二维码</el-button>
              <el-button type="primary" @click="() => setDownloadQrcode(scope.row, scope.$index)">设置客户端下载</el-button>
              <el-button type="primary" @click="() => delQrcode(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
  <JuouProForm ref="addWeappFormRef" is-dialog title="添加小程序二维码" :items="addWeappItems" @ok="handleaddWeappOk"/>
  <JuouProForm ref="addH5FormRef" is-dialog title="添加H5二维码" :items="addH5Items" @ok="handleaddH5Ok"/>
</template>
<script lang="ts" setup>
import { useApi } from '@/hooks/useApi';
import { JuouProForm, JuouProFormItem, useBaseAsync } from 'juou-managebase-ui';
import { keyBy } from 'lodash';
import { ref } from 'vue';
import QrcodeVue from 'qrcode.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { convertBase64UrlToImgFile } from '@/utils';

const addWeappFormRef = ref<InstanceType<typeof JuouProForm>>();
const addWeappItems = ref<JuouProFormItem[]>([]);
const addH5FormRef = ref<InstanceType<typeof JuouProForm>>();
const addH5Items = ref<JuouProFormItem[]>([]);
const emit = defineEmits<{(e: 'setDownload'): void;}>();
const store = ref<any>({});

const qrcodeDialogVisible = ref(false);

// 获取的二维码值
const qrcodeValues = ref<{
  name: string;
  appid?: string;
  h5TplId?: string;
  url: string;
}[]>([]);


const loading = ref(false);

const saveQrcode = (qrcode: any, inx: any) => {
  const qrcodeEl: any = document.getElementById(`qrcode-${inx}`);
  if (!qrcodeEl) {
    return;
  }
  const dataURL = qrcodeEl.toDataURL('image/png');

  const link = document.createElement('a');
  link.href = dataURL;
  link.download = `qrcode-${qrcode.name}.png`;
  link.style.display = 'none';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


const init = useBaseAsync(async() => {
  try {
    loading.value = true;
    qrcodeValues.value = [];
    // 获取小程序列表
    const weappRes = await useApi().wxApi.getRelativeWeappwx({ appid: store.value.wxAppid });
    const weappOptions = weappRes?.data?.data?.filter((item: any) => item.status === 1).map((item: any) => ({
      label: item.nickname,
      value: item.appid,
    }));
    // 获取h5模板列表
    const h5TplRes = await useApi().wxTplApi.listWxTplByTenantwxTpl({ });
    const h5TplOptions = h5TplRes?.data?.data?.map((item: any) => ({
      label: `${item.name}(${item.url})`,
      value: item.id,
    }));

    addWeappItems.value = [
      {
        name: 'appid',
        label: '小程序',
        type: 'select',
        rule: [{ required: true }],
        options: weappOptions,
      },
    ];


    addH5Items.value = [
      {
        name: 'id',
        label: 'H5模板',
        type: 'select',
        rule: [{ required: true }],
        options: h5TplOptions,
      },
    ];

    const weappMap = keyBy(weappOptions as any, (item) => String(item.value));
    const res = await useApi().storeApi.getQrcodesstore({
      storeId: store.value.id,
    });
    Object.entries(res?.data?.data?.weappQrcode || {}).forEach(([appid, url]: any) => {
      const weapp = weappMap[appid];
      if (!weapp) {
        return;
      }
      qrcodeValues.value.push({
        name: `${weapp.label}(小程序)`,
        appid,
        url,
      });
    });

    const h5Map = keyBy(h5TplOptions as any, (item) => item.value);
    Object.entries(res?.data?.data?.wxQrcode || {}).forEach(([h5TplId, url]: any) => {
      const h5Tpl = h5Map[String(h5TplId)];
      if (!h5Tpl) {
        return;
      }
      qrcodeValues.value.push({
        name: `${h5Tpl.label}`,
        h5TplId: String(h5TplId),
        url,
      });
    });

    if (res?.data?.data?.directQrcodeUrl) {
      qrcodeValues.value.push({
        name: '直开',
        url: res?.data?.data?.directQrcodeUrl,
      });
    }
    store.value.downloadQrcodeUrl = res.data.data.downloadQrcodeUrl ? res.data.data.downloadQrcodeUrl.split('_#_#_')[0] : '';
  } finally {
    loading.value = false;
  }
});


const setDownloadQrcode = useBaseAsync(async(qrcode: any, inx: any) => {
  const qrcodeEl: any = document.getElementById(`qrcode-${inx}`);
  if (!qrcodeEl) {
    return;
  }
  const dataURL = qrcodeEl.toDataURL('image/png');
  console.log(dataURL);
  try {
    const res = await useApi().uploadApi.imageUpload([convertBase64UrlToImgFile(dataURL, 'qrcode.png').file]);
    console.log(res);

    if (!res.data.data?.[0]) {
      ElMessage.error('设置失败！');
      return;
    }

    await useApi().storeApi.setDownloadQrcodestore({
      storeId: store.value.id,
      qrcodeUrl: `${qrcode.url}_#_#_${res.data.data?.[0]}`,
    });
    init();
    emit('setDownload');
  } catch (error) {
    console.log(error);
  }
});

const handleaddWeappOk = useBaseAsync(async(data: any) => {
  await useApi().storeApi.addWeappQrcodestore({
    storeId: store.value.id,
    appid: data.appid,
  });
  ElMessage.success('添加成功');
  init();
});

const handleaddH5Ok = useBaseAsync(async(data: any) => {
  await useApi().storeApi.addH5Qrcodestore({
    storeId: store.value.id,
    h5TplId: data.id,
  });
  ElMessage.success('添加成功');
  init();
});


const handleAddDirectQrcode = useBaseAsync(async() => {
  await useApi().storeApi.addDirectQrcodeUrlstore({
    storeId: store.value.id,
  });
  ElMessage.success('添加成功');
  init();
});

const delQrcode = useBaseAsync((data: any) => {
  ElMessageBox.confirm('删除后无法恢复，确定要删除吗？', '删除', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(async() => {
    if (data.name === '直开') {
      await useApi().storeApi.delDirectQrcodestore({
        storeId: store.value.id,
      });
    } else if (data.name.includes('(小程序)')) {
      await useApi().storeApi.delWeappQrcodestore({
        storeId: store.value.id,
        appid: data.appid,
      });
    } else {
      await useApi().storeApi.delWxQrcodestore({
        storeId: store.value.id,
        h5TplId: data.h5TplId,
      });
    }


    ElMessage.success('删除成功');
    init();
  });
});


defineExpose({
  show: async(_store: any) => {
    store.value = { ..._store };
    await init();

    qrcodeDialogVisible.value = true;
  },
});

</script>
<style lang="scss" scoped>
.qrcode {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

</style>

<template>
  <el-dialog v-model="visible" title="模板列表" draggable width="60vw">
    <JuouProTable
      ref="tableRef"
      :columns="columns"
      :get-data="getData"
      :on-delete="deleteFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
    <JuouDangerDialog
      ref="setTplDialog"
      title="选用警告"
      opt-name="选用"
      :on-ok="handleSetTpAction"
      :format-dangder-delete-info="setTplDangderInfo"
    />
  </el-dialog>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  JuouProForm,
  JuouProFormItem,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import dayjs from 'dayjs';

const emit = defineEmits<{(e: 'setTpl', data: any): void;}>();


const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const setTplDialog = ref<InstanceType<typeof JuouDangerDialog>>();

const visible = ref(false);
const addInitValue = {

};
const conditionsInitValue = {
};


// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'templateId',
      label: '模板ID',
      type: 'number',
    },
    {
      name: 'templateType',
      label: '模板类型',
      type: 'select',
      rule: [{ required: true }],
      options: [
        {
          label: '普通模板',
          value: 0,
        },
        {
          label: '标准模板',
          value: 1,
        },
      ],
    },
    {
      name: 'currentVersion',
      label: '版本',
      type: 'string',
    },
    {
      name: 'description',
      label: '描述',
      type: 'string',
      width: 200,
    },
    {
      name: 'auditScene',
      label: '场景',
      type: 'string',
    },
    {
      name: 'auditStatus',
      label: '审核状态',
      type: 'searchSelect',
      options: [
        {
          label: '未提交审核',
          value: 0,
        },
        {
          label: '审核中',
          value: 1,
        },
        {
          label: '审核中',
          value: 2,
        },
        {
          label: '审核通过',
          value: 3,
        },
        {
          label: '提审中',
          value: 4,
        },
        {
          label: '提审失败',
          value: 5,
        },
      ],
    },
    {
      name: 'reason',
      label: '驳回原因',
      type: 'string',
    },
    {
      name: 'createTime',
      label: '创建时间',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { createTime } }: any) => dayjs(createTime).format('YYYY-MM-DD HH:mm:ss'),
    },

  ];
});


const operates: any[] = [
  {
    key: 'setTpl',
    name: '选用当前模板库',
    handler: useBaseAsync((row: any) => {
      setTplDialog.value?.showDialog(row);
    }),
  },
];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().weappTplApi.getTplListweappTpl();
    const tableData: JuouProTableData = {
      total: res.data.data?.length || 0,
      data: res.data.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const handleSetTpAction = useBaseAsync((data: any) => {
  emit('setTpl', data);
  visible.value = false;
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().weappTplApi.delTplweappTpl(data.templateId);
  ElMessage.success('删除成功');
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.templateId})操作，请谨慎操作`;

const setTplDangderInfo = (row: any) => `您正在进行选用模板(${row.templateId})操作，请保证当前所有在用小程序都在正常状态`;

init();
defineExpose({
  show: () => {
    tableRef.value?.refresh();
    visible.value = true;
  },
});

</script>
<style lang="scss" scoped>
</style>

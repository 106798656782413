<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="设置"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-delete="deleteFun"
      :on-edit="updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();


const addInitValue = {

};
const conditionsInitValue = {
};

// todo: 目前是写死，后面需要优化
const menusOption = [
  {
    value: 1394384507314176,
    label: '首页',
  },
  {
    value: 1394371969245184,
    label: '设备库存',
  },
  {
    value: 1394372366057472,
    label: '门店信息',
  },
  {
    value: 1394383362277376,
    label: '距离调整',
  },
  {
    value: 1394375593926656,
    label: '门店数据',
  },
  {
    value: 1394382999953408,
    label: '客户数据',
  },
  {
    value: 1394382581522432,
    label: '服务商',
  },
  {
    value: 1385197922844672,
    label: '微信小程序',
  },
  {
    value: 1382145701535744,
    label: '微信公众号',
  },

  {
    value: 1394380572688384,
    label: '设置',
  },

];


const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'name',
      label: '管理员姓名',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'phone',
      label: '管理员电话',
      type: 'numberStr',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'password',
      label: '管理员密码',
      type: 'password',
      rule: [{ required: true }],
    },
    {
      name: 'menus',
      label: '管理员权限',
      type: 'multiselect',
      placeholder: '留空为所有权限',
      options: menusOption,
      render: ({ row: { menus } }) => {
        if (!menus?.length) {
          return '所有权限';
        }
        return menus.map((item: any) => menusOption.find((mOp) => mOp.value === item)?.label).join(' ');
      },
    },

  ];
});

const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().adminApi.pageAdminadmin({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data?.map((item:any) => ({
        ...item,
        menus: item.menus?.map((menu: any) => menu.id),
      })) || [],
    };
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.idHex})操作，请谨慎操作`;


const addFun = useBaseAsync(async(data: any) => {
  await useApi().adminApi.addAdminadmin({
    adminAdminVo: {
      ...data,
      menus: (data.menus?.length || undefined) && menusOption.filter((item) => data.menus.includes(item.value)).map((item) => ({
        id: item.value,
        name: item.label,
      })),
    },
  });
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  await useApi().adminApi.updateAdminadmin({
    adminAdminVo: {
      ...data,
      menus: (data.menus?.length || undefined) && menusOption.filter((item) => data.menus.includes(item.value)).map((item) => ({
        id: item.value,
        name: item.label,
      })),
    },
  });
  ElMessage.success('修改成功');
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().adminApi.delAdminadmin(data.id);
  ElMessage.success('删除成功');
});


init();

</script>
<style lang="scss" scoped>
</style>

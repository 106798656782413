<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="微信小程序管理"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :on-delete="deleteFun"
      :on-edit="updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :top-operates="topOperates"
      :add-init-values="addInitValue"
      :operation-width="380"
      :codition-init-values="conditionsInitValue"
    />
  </div>
  <el-dialog v-model="visibleAuthQr" title="小程序管理员扫码授权" :width="320">
    <div class="qr" v-loading="qrloading">
      <QrcodeVue
        :value="qrUrl"
        :size="800"
        style="width: 200px; height: 200px"
      />
      <div class="qr-time">
        过期时间：{{ qrExpiresAt }}
      </div>
      <el-button type="primary" @click="() => { visibleAuthQr = false; tableRef?.refresh(); }">已授权完成</el-button>
    </div>
  </el-dialog>
  <JuouProForm ref="setTplFormRef" is-dialog title="选用模板" :items="setTplItems" @ok="handleSetTplOk"/>
  <publish ref="publishRef" @close="() => tableRef?.refresh()"/>
</template>
<script lang="tsx" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  JuouProForm,
  JuouProFormItem,
  useBaseAsync,
  useRoute,
  useRouter,
  useBaseTenantStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import dayjs from 'dayjs';
import { getCurrentRootUrl, getCurrentUrl } from '@/utils';
import QrcodeVue from 'qrcode.vue';
import publish from './publish.vue';


const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const setTplFormRef = ref<InstanceType<typeof JuouProForm>>();
const publishRef = ref<InstanceType<typeof publish>>();


const baseTenantStore = useBaseTenantStore();
const addInitValue = {

};
const conditionsInitValue = {
};

const setTplItems = ref<JuouProFormItem[]>([]);
const currentWeapp = ref<any>();
let tpls = [] as any[];
const publisStatusOptions = [
  {
    label: '未发布',
    value: undefined,
  },
  {
    label: '需要更新',
    value: 'need_update',
  },
  {
    label: '审核中',
    value: 'review',
  },
  {
    label: '审核失败',
    value: 'review_failure',
  },
  {
    label: '已发布',
    value: 'success',
  },
];

// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(async() => {
  const tplRes = await useApi().weappTplApi.listWeappTplByTenantweappTpl({});
  tpls = tplRes.data.data || [];
  const tplOptions = tpls.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));

  setTplItems.value = [
    {
      name: 'templateId',
      label: '模板',
      type: 'select',
      rule: [{ required: true }],
      options: tplOptions,
    },
  ];


  columns.value = [

    {
      name: 'remark',
      label: '备注',
      type: 'string',
      isCondition: true,
    },
    {
      name: 'name',
      label: '小程序名称',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
      hideEdit: true,
    },
    {
      name: 'appid',
      label: 'AppID',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
      hideEdit: true,
    },
    {
      name: 'principalName',
      label: '主体名称',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
      hideEdit: true,
    },
    {
      name: 'baseSetting',
      label: '基础配置状态',
      type: 'select',
      options: [
        {
          label: '完成',
          value: true,
        },
        {
          label: '未完成',
          value: false,
        },
      ],
      hideCreate: true,
      hideEdit: true,
      manualRefresh: (row) => {
        row.baseSetting = '加载中...';
        useApi()
          .weappApi.checkBaseSettingweapp({
            appid: row.appid,
          })
          .then((statusRes) => {
            row.baseSetting = statusRes.data.data;
            if (!statusRes.data.data) {
              useApi().weappApi.setBaseSettingweapp({
                appid: row.appid,
              }).then(() => {
                ElMessage.success('重新设置成功，请稍后再次刷新状态');
              });
            }
          })
          .catch(() => {
            row.baseSetting = false;
          });
      },
    },

    /* {
      name: 'privacySetting',
      label: '隐私申明状态',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
      manualRefresh: (row) => {
        row.privacySetting = '加载中...';
        useApi()
          .weappApi.checkPrivacySettingweapp({
            appid: row.appid,
          })
          .then((statusRes) => {
            row.privacySetting = statusRes.data.data;
          })
          .catch(() => {
            row.privacySetting = false;
          });
      },
    }, */
    {
      name: 'localtionInterface',
      label: '定位接口状态',
      type: 'select',
      options: [
        {
          label: '待申请开通',
          value: 1,
        },
        {
          label: '无权限',
          value: 2,
        },
        {
          label: '申请中',
          value: 3,
        },
        {
          label: '申请失败',
          value: 4,
        },
        {
          label: '已开通',
          value: 5,
        },
      ],
      hideCreate: true,
      hideEdit: true,
      manualRefresh: (row) => {
        row.localtionInterface = '加载中...';
        useApi()
          .weappApi.checkPrivacyInterfaceweapp({
            appid: row.appid,
          })
          .then((statusRes) => {
            row.localtionInterface = statusRes.data.data?.find((inf: any) => inf.name === 'wx.getLocation')?.status || 0;
            if ([1, 4].includes(row.localtionInterface)) {
              useApi().weappApi.setPrivacyInterfaceweapp({
                appid: row.appid,
              }).then(() => {
                ElMessage.success('重新申请成功，请稍后再次刷新状态');
              });
            }
          })
          .catch(() => {
            row.localtionInterface = 0;
          });
      },
    },
    {
      name: 'templateId',
      label: '模板',
      type: 'select',
      rule: [{ required: true }],
      options: tplOptions,
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'status',
      label: '发布状态',
      type: 'select',
      options: publisStatusOptions,
      hideCreate: true,
      hideEdit: true,
      manualRefresh: (row) => {
        row.status = '加载中...';
        useApi()
          .weappApi.checkStatusweapp({
            appid: row.appid,
          })
          .then((statusRes) => {
            row.status = statusRes.data.data;
          })
          .catch(() => {
            row.status = undefined;
          });
      },
    },

    {
      name: 'createTime',
      label: '授权时间',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { createTime } }: any) => dayjs(createTime).format('YYYY-MM-DD HH:mm:ss'),
    },

  ];
});

const qrUrl = ref('');
const qrExpiresAt = ref('');
const visibleAuthQr = ref(false);
const qrloading = ref(false);

const topOperates: any[] = [
  {
    key: 'jumpAdd',
    name: '授权微信小程序',
    handler: async() => {
      visibleAuthQr.value = true;
      qrloading.value = true;
      try {
        const res = await useApi().weappApi.getWeappAuthUrlweapp({
          redirectUri: encodeURIComponent(`${getCurrentRootUrl()}${
            baseTenantStore.tenantInfo?.id ? `/___tenant_${baseTenantStore.tenantInfo?.id}` : ''
          }/auth?type=2`),
        });
        qrUrl.value = res.data.data?.url;
        qrExpiresAt.value = res.data.data?.expiresAt;
      } finally {
        qrloading.value = false;
      }
    },
  },
];
const operates: any[] = [

  {
    key: 'setTpl',
    name: '选用模板',
    handler: (row: any) => {
      currentWeapp.value = row;
      setTplFormRef.value?.setOneFormState('templateId', row.templateId || undefined);
      setTplFormRef.value?.showDialog();
      console.log(row);
    },
  },
  {
    key: 'publish',
    name: '配置发布',
    handler: (row: any) => {
      const tpl = tpls.find((item) => item.id === row.templateId);
      if (!tpl) {
        ElMessage.error('请选用模板后再配置发布');
        return;
      }
      if (row.baseSetting !== true) {
        ElMessage.error('请刷新基础配置状态，待完成后可配置发布');
        return;
      }

      if (row.localtionInterface !== 5) {
        ElMessage.error('请刷新定位接口状态，待完成后可配置发布');
        return;
      }


      publishRef.value?.show({ ...row }, { ...tpl });
    },
  },
];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().weappApi.pageWeappweapp({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data?.map((item: any) => ({
        ...item,
        localtionInterface: item.privacyInterface?.find((inf: any) => inf.name === 'wx.getLocation')?.status || 0,
      })) || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;


const updateFun = useBaseAsync(async(data: any) => {
  await useApi().weappApi.updateWeappweapp({
    weappAdminVo: data,
  });
  ElMessage.success('修改成功');
});


const handleSetTplOk = useBaseAsync(async(data: any) => {
  await useApi().weappApi.updateWeappweapp({
    weappAdminVo: {
      ...currentWeapp.value,
      templateId: data.templateId,
    },
  });
  ElMessage.success('设置成功');
  tableRef.value?.refresh();
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().weappApi.delWeappweapp(data.appid);
  ElMessage.success('删除成功');
});


init();


</script>
<style lang="scss" scoped>
.qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;

  &-time {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>

<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="距离调整"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-delete="deleteFun"
      :on-edit="updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();


const addInitValue = {

};
const conditionsInitValue = {
};


const init = useBaseAsync(async() => {
  const res = await useApi().storeApi.listStorestore({ conditions: {} });
  console.log(res);
  const storeOptions
    = res.data.data?.map((item: any) => ({
      value: item.id,
      label: item.name,
    })) || [];
  columns.value = [
    {
      name: 'remark',
      label: '备注信息',
      type: 'string',
      isCondition: true,
      order: 10,
    },
    {
      name: 'storeId',
      label: '店铺名称',
      width: 80,
      rule: [{ required: true }],
      type: 'searchSelect',
      options: storeOptions,
      render: ({ row: { storeId } }) => {
        const facilitator = storeOptions.find((item: any) => item.value === storeId);
        if (!facilitator) {
          return '';
        }
        return facilitator.label;
      },
    },
    {
      name: 'storeName',
      label: '店铺名称',
      type: 'string',
      isCondition: false,
      hideTable: true,
      hideEdit: true,
      hideCreate: true,
    },
    {
      name: 'phone',
      label: '客户电话',
      type: 'numberStr',
      width: 80,
      rule: [{ required: true }],
      isCondition: true,
    },
    {
      name: 'distance',
      label: '开门距离',
      type: 'number',
      min: 0,
      placeholder: '开门距离(米)',
      render: ({ row: { distance } }) => `${distance}米`,
      rule: [{ required: true }],
    },

  ];
});

const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().distanceApi.pageDistancedistance({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data?.map((item:any) => ({
        ...item,
        menus: item.menus?.map((menu: any) => menu.id),
      })) || [],
    };
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => '您正在进行删除操作，请谨慎操作';


const addFun = useBaseAsync(async(data: any) => {
  await useApi().distanceApi.addDistancedistance({
    distanceAdminVo: {
      ...data,
    },
  });
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  await useApi().distanceApi.updateDistancedistance({
    distanceAdminVo: {
      ...data,
    },
  });
  ElMessage.success('修改成功');
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().distanceApi.delDistancedistance(data.id);
  ElMessage.success('删除成功');
});


init();

</script>
<style lang="scss" scoped>
</style>

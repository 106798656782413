<template>
  <div class="bindwx" v-if="loginCode && !loginLoading">
    <h1>绑定用户</h1>
    <el-form :model="form" ref="formRef" label-width="auto" :rules="rules" style="max-width: 600px">
      <el-form-item label="手机号">
        <div class="bindwx-phone">
          <el-input  v-model="form.phone" />
          <el-button type="primary" @click="sendSms" :disabled="countDown > 0">
            {{ countDown > 0 ?
              `${countDown}秒后重新发送` : '发送验证码' }}
          </el-button>
        </div>

      </el-form-item>
      <el-form-item label="验证码">
        <el-input v-model="form.phoneCode" />
      </el-form-item>
      <el-form-item >
        <el-button type="primary" style="width: 100%;" @click="handleBind" :loading="bindLoading">绑定</el-button>
      </el-form-item>
    </el-form>

  </div>
  <div class="bindwx" v-else-if="loginLoading">
    <h1>登录中...</h1>
  </div>
  <div class="bindwx" v-else>
    <h1>等待授权...</h1>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue';
import {
  useBaseUserStore,
  useRoute,
  useRouter,
} from 'juou-managebase-ui';
import { ElForm, ElMessage } from 'element-plus';
import { useApi } from '@/hooks/useApi';
import { useWx } from '@/hooks/useWx';

const wx = useWx();
const baseUserStore = useBaseUserStore();
const route = useRoute();
const router = useRouter();
const countDown = ref(0);


const getCurrentUrl = () => {
  const { protocol, hostname, pathname } = window.location;
  return encodeURI(`${protocol}//${hostname}${pathname}`);
};

const loginCode = ref('');
const wxappid = ref('');
const tips = ref('');
const form = ref({
  phone: '',
  phoneCode: '',
});
const formRef = ref<InstanceType<typeof ElForm>>();

const rules = reactive<any>({
  phone: [
    { required: true, message: '请输入手机号' },
    // eslint-disable-next-line prefer-named-capture-group
    { regex: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/u, message: '手机号格式错误' },
  ],
  phoneCode: [
    { required: true, message: '请输入验证码' },
    { regex: /^\d{6}$/u, message: '请输入6位数字验证码' },
  ],
});

const back = () => {
  if (tips.value) {
    alert(tips.value);
  }
  wx.closeWindow();
};


const countDownStart = () => {
  countDown.value = 60;
  const timer = setInterval(() => {
    countDown.value--;
    if (countDown.value === 0) {
      clearInterval(timer);
    }
  }, 1000);
};


const sendSms = async() => {
  await formRef.value?.validate();

  try {
    await useApi().wxApi.sendCodewx({
      phone: Number(form.value.phone),
    });
    countDownStart();
    ElMessage.success('发送成功');
  } catch (error) {
    ElMessage.error('发送失败，请勿频繁发送');
  }
};

const loginLoading = ref(false);

const handleLogin = async() => {
  try {
    loginLoading.value = true;
    const res = await useApi().wxApi.loginwx({
      loginCode: loginCode.value,
    }, {
      headers: {
        appid: wxappid.value,
      },
    });
    baseUserStore.setLoginInfo({
      accessToken: res.data.data.token,
      phone: res.data.data.phone,
      meta: res.data.data.meta,
    });
    ElMessage.success('登录成功');
    back();
  } catch (error) {
    console.log(error);
    // 登录失败则进入手机号注册
    window.location.href = `${getCurrentUrl()}?state=register_${tips.value}&appid=${wxappid.value}`;
  } finally {
    loginLoading.value = false;
  }
};

const bindLoading = ref(false);

const handleBind = async() => {
  await formRef.value?.validate();

  try {
    bindLoading.value = true;
    await useApi().wxApi.registerwx({
      phone: Number(form.value.phone),
      phoneCode: form.value.phoneCode,
      loginCode: loginCode.value,
    }, {
      headers: {
        appid: wxappid.value,
      },
    });
    ElMessage.success('绑定成功');
    window.location.href = `${getCurrentUrl()}?state=${tips.value}&appid=${wxappid.value}`;
  } catch (error) {
    ElMessage.error('绑定失败');
  } finally {
    bindLoading.value = false;
  }
};


onMounted(async() => {
  const { code, state = '', appid } = route.query;
  tips.value = state as string;
  wxappid.value = appid as string;
  loginCode.value = code as string;

  /*   if (baseUserStore.token) {
    back();
    return;
  } */


  const currentUrl = getCurrentUrl();
  if (!code) {
    const res = await useApi().wxApi.getWxOpenAppidwx();


    window.location.href
      = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${
        currentUrl
      }&response_type=code&state=${state}&scope=snsapi_userinfo&component_appid=${res.data.data}#wechat_redirect`;
    return;
  }

  if ((state as string).startsWith('register_')) {
    tips.value = (state as string).slice('register_'.length);
  } else {
    handleLogin();
  }
});

</script>

<style lang="scss" scoped>
.bindwx {
  box-sizing: border-box;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: #006cb2;
  }

  &-phone {
    display: flex;
    gap: 16px;
  }
}

</style>

<template>
  <el-dialog v-model="visible" title="关联小程序" draggable width="60vw">
    <JuouProTable
      ref="tableRef"
      :columns="columns"
      :get-data="getData"
      :on-delete="deleteFun"
      delete-text="解除关联"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
    <JuouDangerDialog
      ref="setPrimaryDialog"
      title="警告"
      opt-name="设置"
      :on-ok="handleSetPrimaryAction"
      :format-dangder-delete-info="setPrimaryDangderInfo"
    />
  </el-dialog>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  JuouProForm,
  JuouProFormItem,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import dayjs from 'dayjs';

const emit = defineEmits<{(e: 'setPrimary', data: any): void;}>();


const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const setPrimaryDialog = ref<InstanceType<typeof JuouDangerDialog>>();
const appid = ref('');
const visible = ref(false);
const addInitValue = {

};
const conditionsInitValue = {
};


// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'nickname',
      label: '小程序名称',
      type: 'string',
    },
    {
      name: 'appid',
      label: '小程序APPID',
      type: 'string',
    },
    {
      name: 'status',
      label: '关联状态',
      type: 'select',
      options: [
        {
          label: '未关联',
          value: 0,
        },
        {
          label: '已关联',
          value: 1,
        },
        {
          label: '待小程序管理员确认',
          value: 2,
        },
        {
          label: '小程序管理员拒绝关联',
          value: 3,
        },
        {
          label: '待公众号管理员确认',
          value: 12,
        },
      ],
    },

  ];
});


const currentRow = ref<any>();

const operates: any[] = [
  {
    key: 'relate',
    name: '建立关联',
    handler: useBaseAsync(async(row: any) => {
      await useApi().wxApi.relativeWeappwx({
        appid: appid.value,
        weappAppid: row.appid,
      });
      ElMessage.success('建立成功');
      tableRef.value?.refresh();
    }),
  },
  {
    key: 'setPrimary',
    name: '设为主小程序',
    handler: useBaseAsync((row: any) => {
      currentRow.value = row;
      setPrimaryDialog.value?.showDialog(row);
    }),
  },
];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().wxApi.getRelativeWeappwx({
      appid: appid.value,
    });
    const tableData: JuouProTableData = {
      total: res.data.data?.length || 0,
      data: res.data.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const handleSetPrimaryAction = useBaseAsync(() => {
  emit('setPrimary', currentRow.value.appid);
  visible.value = false;
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().wxApi.cancelRelativeWeappwx({
    appid: appid.value,
    weappAppid: data.appid,
  });
  ElMessage.success('解除成功');
});

const formatDeleteInfo = (row: any) => `您正在进行解除关联(${row.nickname})操作，请谨慎操作`;

const setPrimaryDangderInfo = (row: any) => `您正在进行设置主小程序(${row.nickname})操作`;

init();
defineExpose({
  show: (_appid: string) => {
    appid.value = _appid;
    tableRef.value?.refresh();
    visible.value = true;
  },
});

</script>
<style lang="scss" scoped>
</style>

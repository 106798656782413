<template>
  <div class="advertising-item" v-loading="loading">
    <img :src="`${useApi().baseUrl}${data.image}`" style="height: 40px;" alt="">
    <div class="advertising-item-type">
      {{ data.type === 'weapp' ? '小程序' : '插件' }}
    </div>
    <div>
      URL/Path：{{ data.url }}
    </div>
    <div >
      appid: {{ data.appid }}
    </div>
    <div class="advertising-item-op">
      <div v-if="!isH && !isFirst" class="up" @click="() => move('up')">
        <el-icon>
          <ArrowUpBold />
        </el-icon> 前移
      </div>
      <div v-if="!isH && !isLast" class="down" @click="() => move('down')">
        <el-icon>
          <ArrowDownBold />
        </el-icon> 后移
      </div>
      <div v-if="isH && !isFirst" class="left" @click="() => move('up')">
        <el-icon>
          <ArrowLeftBold />
        </el-icon> 前移
      </div>
      <div v-if="isH && !isLast" class="right" @click="() => move('down')">
        <el-icon>
          <ArrowRightBold />
        </el-icon> 后移
      </div>
      <!-- <div class="edit">
        <el-icon>
          <Edit />
        </el-icon> 编辑
      </div> -->
      <div class="del" @click="() => del()">
        <el-icon>
          <Delete />
        </el-icon> 删除
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useApi } from '@/hooks/useApi';
import { useBaseAsync } from 'juou-managebase-ui';
import { ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

const loading = ref(false);


const props = defineProps<{
  data: any;
  isH?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}>();

const emits = defineEmits(['change']);


const move = useBaseAsync(async(type: 'up' | 'down') => {
  try {
    loading.value = true;
    await useApi().advertisingApi.moveAdvertisingadvertising({
      advertisingId: props.data.id,
      up: type === 'up',
    });
    emits('change');
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
});

const del = useBaseAsync(() => {
  ElMessageBox.confirm('删除后无法恢复，确定要删除吗？', '删除', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(async() => {
    try {
      loading.value = true;
      await useApi().advertisingApi.delAdvertisingadvertising(props.data.id);
      emits('change');
    } catch (error) {
      console.log(error);
    } finally {
      loading.value = false;
    }
  });
});

</script>

<style lang="scss">
.advertising-item {
  min-height: 120px;
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  border: solid 1px #000;
  position: relative;

  &-op {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 12px;
    color: #000;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;


  }

}</style>

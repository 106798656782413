<template>
  <div class="advertising-row" v-loading="loading">
    <div v-if="rowData.type === 'carousel'" class="advertising-carousel">
      <div class="juou-title">
        轮播
      </div>
      <AdvertisingItem
        v-for="(item, inx) in datas"
        :key="item.id"
        :data="item"
        :is-first="inx === 0"
        :is-last="inx === datas.length - 1"
        @change="() => init()"
        />
      <div class="advertising-add" @click="() => addDialogRef?.showDialog()">
        <el-icon>
          <Plus />
        </el-icon> 添加一条
      </div>
    </div>
    <div v-else-if="rowData.type === 'distribute'" class="advertising-distribute">
      <div class="juou-title">
        均匀分布
      </div>
      <div class="advertising-distribute-content">
        <AdvertisingItem
          v-for="(item, inx) in datas"
          :key="item.id"
          :data="item"
          :is-first="inx === 0"
          :is-last="inx === datas.length - 1"
          @change="() => init()"
          is-h />
        <div class="advertising-add" @click="() => addDialogRef?.showDialog()">
          <el-icon>
            <Plus />
          </el-icon> 添加一条
        </div>
      </div>

    </div>

    <!-- 操作 -->
    <div class="advertising-row-op">
      <div v-if="!isFirst" class="up" @click="() => moveRow('up')">
        <el-icon>
          <ArrowUpBold />
        </el-icon> 上移
      </div>
      <div v-if="!isLast" class="down" @click="() => moveRow('down')">
        <el-icon>
          <ArrowDownBold />
        </el-icon> 下移
      </div>
      <div class="del" @click="() => delRow()">
        <el-icon>
          <Delete />
        </el-icon> 删除
      </div>
    </div>
    <JuouProForm ref="addDialogRef" :is-dialog="true" title="添加一条" :items="items" @ok="handleAdd"/>

  </div>
</template>

<script lang="ts" setup>
import { JuouProForm, JuouProFormItem, useBaseAsync, useBaseTenantStore, useBaseUserStore } from 'juou-managebase-ui';
import AdvertisingItem from './AdvertisingItem.vue';
import { useApi } from '@/hooks/useApi';
import { ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

const loading = ref(false);

const props = defineProps<{
  rowData: any;
  isFirst?: boolean;
  isLast?: boolean;
}>();

const addDialogRef = ref<InstanceType<typeof JuouProForm>>();


const typeOp: {
    value: any;
    label: string;
}[] = [
  {
    label: '小程序',
    value: 'weapp',
  },
  {
    label: '插件',
    value: 'plugin',
  },
];

const items: JuouProFormItem[] = [
  {
    name: 'image',
    label: '图片',
    rule: [{ required: true }],
    type: 'image',
    action: useApi().uploadImageUrl,
    multiple: false,
    onSuccess(data) {
      console.log(data.data[0]);
      addDialogRef.value?.setOneFormState('image', data.data[0]);
    },
  },
  {
    type: 'select',
    label: '类型',
    name: 'type',
    options: typeOp,
    rule: [{ required: true }],
  },
  {
    type: 'string',
    label: 'URL/Path',
    name: 'url',
    rule: [{ required: true }],
  },
  {
    type: 'string',
    label: 'appid',
    name: 'appid',
    rule: [{ required: true }],
  },
];

const datas = ref<any[]>([]);

const emits = defineEmits(['change']);

const init = useBaseAsync(async() => {
  try {
    loading.value = true;
    const res = await useApi().advertisingApi.listAdvertisingadvertising({
      conditions: {
        rowId: props.rowData.id,
      },
    });
    if (res?.data?.data) {
      datas.value = res.data.data;
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
});


const moveRow = useBaseAsync(async(type: 'up' | 'down') => {
  try {
    loading.value = true;
    await useApi().advertisingApi.moveAdvertisingRowadvertising({
      advertisingRowId: props.rowData.id,
      up: type === 'up',
    });
    emits('change');
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
});

const delRow = useBaseAsync(() => {
  ElMessageBox.confirm('删除后无法恢复，确定要删除吗？', '删除', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(async() => {
    try {
      loading.value = true;
      await useApi().advertisingApi.delAdvertisingRowadvertising(props.rowData.id);
      emits('change');
    } catch (error) {
      console.log(error);
    } finally {
      loading.value = false;
    }
  });
});

const handleAdd = useBaseAsync(async(data: any) => {
  try {
    await useApi().advertisingApi.addAdvertisingadvertising({
      advertisingAdminVo: {
        rowId: props.rowData.id,
        sequence: datas.value.length + 1,
        ...data,
      },
    });
    ElMessage.success('添加成功');
    addDialogRef.value?.reset();
    init();
  } catch (error) {
    console.log(error);
  }
});

init();


</script>

<style lang="scss">
.advertising {

  &-row {
    position: relative;

    &-op {
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 12px;
      color: #000;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;


    }
  }

  &-carousel {
    border: solid 1px #000000;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
  }

  &-distribute {
    border: solid 1px #030303;
    display: flex;
    flex-direction: column;
    padding: 16px;

    &-content {
      display: flex;

      >* {
        flex: 1;
      }
    }
  }

  &-add {
    cursor: pointer;
    border: dashed 1px #bcbcbc;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }
}</style>

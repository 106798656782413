<template>
  <view class="success-page">
    <img
      :src="bannerImg"
      class="radius" style="width: 100%; margin-bottom: 2px;"  />
    <div class="top">
      <nut-avatar size="large" shape="round" style="overflow: hidden;">
        <img :src="LogoImg" />
      </nut-avatar>
      <div class="ellipsis" style="text-align: center; width: 100%;">{{ returnData?.storeName || '' }}</div>
    </div>
    <div class="mid">
      <div class="mid-item">
        <span class="label solid-cell">开门状态</span>
        <span class="ellipsis">{{ returnData?.statusMsg || '开门成功' }}</span>
      </div>
      <div class="mid-item">
        <span class="label solid-cell">店主手机号</span>
        <text :selectable="true" :user-select="true">{{ returnData?.storePhone || '' }}</text>
      </div>
    </div>
    <!-- <div class="ad-box">
      <ad unit-id="adunit-5b916a3523948083" ad-type="video" ad-theme="white"></ad>
    </div> -->
    <component :is="loading.cnt" />
  </view>
</template>

<script lang="ts" setup>
import LogoImg from '@/assets/images/logo.png';
import { useLoading } from '@/hooks/useLoading';
import bannerImg from '@/assets/images/banner.jpg';
import { useBaseAsync, useRoute } from 'juou-managebase-ui';
import { ref } from 'vue';
import { useApi } from '@/hooks/useApi';

const route = useRoute();
const returnData = ref(history.state?.data || {});
console.log(returnData);
const loading = useLoading();

const directOpendoor = useBaseAsync(async(storeId: number) => {
  try {
    loading.show('开锁中');
    const res = await useApi().storeApi.opendoorByDirectstore({
      storeId,
    });
    returnData.value = res?.data?.data || {};
  } finally {
    loading.hide();
  }
});

if (route.query.storeId) {
  directOpendoor(Number(route.query.storeId as string));
}
</script>

<style lang="scss">
.success-page {
  padding: 16px;
  box-sizing: border-box;

  .top {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  .mid {
    margin-bottom: 32px;
  }

  .mid-item {
    padding: 6px 4px;
    display: flex;
    justify-content: space-between;

    .label {
      color: rgb(125, 125, 125);
    }

    span, text {
      font-size: 13px;
    }
  }


}
</style>

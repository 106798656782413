<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="门店数据"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />

  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
  useBaseOperateStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { computed, ref } from 'vue';
import { getTodaySec, todaySecFormat, todaySecToDayjs } from '@/utils';
import dayjs from 'dayjs';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const baseOperateStore = useBaseOperateStore();

const addInitValue = {

};
const conditionsInitValue = {
};
const currentRow = ref<any>();

// 可管理服务商，则是管理员
const canAdminFacilitator = computed(() => baseOperateStore.operates?.find((item) => item.key === 'ADMIN_STORE_FACILITATOR'));

// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(() => {
  columns.value = [
    ...(
      canAdminFacilitator.value ? [
        {
          name: 'remark',
          label: '设备备注',
          type: 'string',
          isCondition: true,
          hideEdit: true,
          hideCreate: true,
          order: 10,
        },
      ] : []
    ) as any,
    {
      name: 'name',
      label: '店铺名称',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'phone',
      label: '店主手机号',
      type: 'numberStr',
      rule: [{ required: true }],
      isCondition: true,
    },
    {
      name: 'allCount',
      label: '历史开门次数',
      type: 'numberStr',
    },
    {
      name: 'allPeople',
      label: '历史开门人数',
      type: 'numberStr',
    },
    {
      name: 'lastdayCount',
      label: '昨日开门次数',
      type: 'numberStr',
    },
    {
      name: 'lastdayPeople',
      label: '昨日开门人数',
      type: 'numberStr',
    },
  ];
});


const operates: any[] = [];

const loading = ref(false);


// eslint-disable-next-line max-lines-per-function
const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().storeApi.pageStorestore({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.idHex})操作，请谨慎操作`;


init();

</script>
<style lang="scss" scoped>
</style>

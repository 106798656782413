<template>
  <el-dialog v-model="visible" title="模板草稿" draggable width="60vw">
    <JuouProTable
      ref="tableRef"
      :columns="columns"
      :get-data="getData"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
    <JuouProForm ref="addFormRef" is-dialog title="添加到模板库" :items="items" @ok="handleOk"/>
  </el-dialog>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  JuouProForm,
  JuouProFormItem,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import dayjs from 'dayjs';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const addFormRef = ref<InstanceType<typeof JuouProForm>>();

const visible = ref(false);
const addInitValue = {

};
const conditionsInitValue = {
};

const currentDraftId = ref<number>();

const items: JuouProFormItem[] = [
  {
    name: 'templateType',
    label: '模板类型',
    type: 'select',
    rule: [{ required: true }],
    options: [
      {
        label: '普通模板',
        value: 0,
      },
      {
        label: '标准模板',
        value: 1,
      },
    ],
  },
];


const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'draftId',
      label: '草稿ID',
      type: 'number',
    },
    {
      name: 'currentVersion',
      label: '版本',
      type: 'string',
    },
    {
      name: 'description',
      label: '描述',
      type: 'string',
    },
    {
      name: 'createTime',
      label: '创建时间',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { createTime } }: any) => dayjs(createTime).format('YYYY-MM-DD HH:mm:ss'),
    },

  ];
});


const operates: any[] = [
  {
    key: 'addTpl',
    name: '添加到模板库',
    handler: useBaseAsync((row: any) => {
      // tpldraftRef.value?.show();
      currentDraftId.value = row.draftId;
      addFormRef.value?.reset();
      addFormRef.value?.showDialog();
    }),
  },
];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().weappTplApi.getTplDraftListweappTpl();
    const tableData: JuouProTableData = {
      total: res.data.data?.length || 0,
      data: res.data.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const handleOk = useBaseAsync(async(data: any) => {
  data.draftId = currentDraftId.value;
  await useApi().weappTplApi.addTplweappTpl(data);
  ElMessage.success('添加成功');
  visible.value = false;
});


const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;


init();
defineExpose({
  show: () => {
    tableRef.value?.refresh();
    visible.value = true;
  },
});

</script>
<style lang="scss" scoped>
</style>

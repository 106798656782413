<template>
  <div class="auth" v-if="(typeof result) !== 'boolean'">
    <el-result
        icon="info"
        title="授权"
        sub-title="正在查询授权结果..."
      >
    </el-result>
  </div>
  <div class="auth" v-else-if="result">
    <el-result
        icon="success"
        title="授权成功"
      >
      <template #extra>
        <div class="auth-info">
          <div>
            名称：{{ authInfo.name }}
          </div>
          <div>
            AppId：{{ authInfo.appid }}
          </div>
          <div>
            主体名称：{{ authInfo.principalName }}
          </div>
        </div>
      </template>
    </el-result>

  </div>
  <div class="auth" v-else>
    <el-result
        icon="error"
        title="授权失败"
        sub-title="请重新扫码授权或联系技术人员"
      >
    </el-result>
  </div>
</template>
<script lang="ts" setup>
import { useApi } from '@/hooks/useApi';
import { useBaseAsync, useRoute, useRouter } from 'juou-managebase-ui';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';


const route = useRoute();
const router = useRouter();
const authInfo = ref({} as any);

const result = ref<boolean>();
const addWx = useBaseAsync(async(authCode: string) => {
  try {
    const res = await useApi().wxApi.addWxwx({
      authCode,
    });
    if (!res.data.data) {
      throw new Error();
    }
    authInfo.value = res.data.data || {};
    result.value = true;
  } catch (error) {
    result.value = false;
  }
});

const addWeapp = useBaseAsync(async(authCode: string) => {
  try {
    const res = await useApi().weappApi.addWeappweapp({
      authCode,
    });
    if (!res.data.data) {
      throw new Error();
    }
    authInfo.value = res.data.data || {};
    result.value = true;
  } catch (error) {
    result.value = false;
  }
});

if (route.query?.auth_code && route.query?.type) {
  const { auth_code: authCode, type } = route.query as any;
  if (type === '1') {
    addWx(authCode);
  } else {
    addWeapp(authCode);
  }
} else {
  result.value = false;
}

</script>
<style lang="scss" scoped>

.auth {
  width: 100vw;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  font-size: 32px;

  &-info {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 16px;

  }

}

</style>

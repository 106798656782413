import dayjs, { Dayjs } from 'dayjs';

/**
 * 获取当天的秒数
 * @param date 日期
 * @returns
 */
export function getTodaySec(date: Dayjs) {
  return date.second() + (date.minute() * 60) + (date.hour() * 3600);
}

/**
 * 当天秒数转换成dayjs
 * @param secs 当天的秒数
 * @returns
 */
export function todaySecToDayjs(secs: number) {
  const sec = secs % 60;
  const hour = Math.floor(secs / 3600);
  const min = Math.floor(secs % 3600 / 60);
  const res = dayjs();
  return res.hour(hour).minute(min)
    .second(sec);
}


export function to2digitNum(num: number) {
  return num < 10 ? `0${num.toFixed(0)}` : num.toFixed(0);
}


/**
 * 当天秒数格式化
 * @param secs 当天的秒数
 * @returns
 */
export function todaySecFormat(secs: number) {
  const sec = secs % 60;
  const hour = Math.floor(secs / 3600);
  const min = Math.floor(secs % 3600 / 60);
  return `${to2digitNum(hour)}:${to2digitNum(min)}:${to2digitNum(sec)}`;
}


/**
 * 格式化dayjs
 * @param date dayjs
 * @param isTime 是否为时间
 * @returns
 */
export function formatDayjs(date: Dayjs, isTime?: boolean) {
  return date.format(isTime ? 'HH:mm:ss' : 'YYYY-MM-DD HH:mm:ss');
}

export const getCurrentUrl = () => {
  const { protocol, hostname, pathname } = window.location;
  return encodeURI(`${protocol}//${hostname}${pathname}`);
};

export const getCurrentRootUrl = () => {
  const { protocol, hostname } = window.location;
  return encodeURI(`${protocol}//${hostname}`);
};

/**
* 有些浏览器(如edge)不支持new File，所以为了兼容，base64要先转换成blob再设置type，name，lastModifiedDate
* 属性间接转换成文件，而不推荐直接new File()的方式
**/
export function convertBase64UrlToImgFile(base64Data: string, fileName: string) {
  const dataArr = base64Data.split(',');
  // 获取文件类型。使用正则捕获 image/jpeg
  // eslint-disable-next-line prefer-named-capture-group
  const imageType = dataArr[0].match(/:(.*?);/u)?.[1] || 'image/png';

  const bytes = window.atob(dataArr[1]);
  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length);
  const ia = new Int8Array(ab);
  let i;
  for (i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  console.log(imageType);
  // 转换成文件，添加文件的type，name，lastModifiedDate属性
  const blob = new Blob([ab], { type: imageType }) as File;

  return {
    file: new File([blob], fileName),
    imageType,
  };
}

<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="h5模板"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :on-add="baseOperateStore.operates?.find(item => item.key === 'ADD_H5_TPL') && addFun"
      :on-delete="baseOperateStore.operates?.find(item => item.key === 'DELETE_H5_TPL') && deleteFun"
      :on-edit="baseOperateStore.operates?.find(item => item.key === 'EDIT_H5_TPL') && updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  useBaseOperateStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import dayjs from 'dayjs';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const baseOperateStore = useBaseOperateStore();

const addInitValue = {

};
const conditionsInitValue = {
};


const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'name',
      label: '模板名称',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'currentVersion',
      label: '当前版本',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'url',
      label: '模板url',
      type: 'string',
      rule: [{ required: true }],
    },

    {
      name: 'createTime',
      label: '创建时间',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { createTime } }: any) => dayjs(createTime).format('YYYY-MM-DD HH:mm:ss'),
    },

  ];
});


const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().wxTplApi.pageWxTplwxTpl({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;


const addFun = useBaseAsync(async(data: any) => {
  await useApi().wxTplApi.addWxTplwxTpl(data);
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  await useApi().wxTplApi.updateWxTplwxTpl(data);
  ElMessage.success('修改成功');
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().wxTplApi.delWxTplwxTpl(data.id);
  ElMessage.success('删除成功');
});


init();

</script>
<style lang="scss" scoped>
</style>

<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="门店信息"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-delete="deleteFun"
      :on-edit="updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
    <JuouProForm ref="bindWxFormRef" is-dialog title="绑定公众号" :items="bindWxItems" @ok="handleBindWxOk"/>
    <qrcode ref="qrcodeRef"  />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
  useBaseOperateStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { computed, ref } from 'vue';
import { getTodaySec, todaySecFormat, todaySecToDayjs } from '@/utils';
import dayjs from 'dayjs';
import qrcode from './qrcode.vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const bindWxFormRef = ref<InstanceType<typeof JuouProForm>>();
const qrcodeRef = ref<InstanceType<typeof qrcode>>();
const bindWxItems = ref<JuouProFormItem[]>([]);

const baseOperateStore = useBaseOperateStore();

const loading = ref(false);
const addInitValue = {
  startTime: dayjs('2000-01-01 00:00:00').toDate(),
  endTime: dayjs('2000-01-01 00:00:00').toDate(),
};
const conditionsInitValue = {
};
const currentRow = ref<any>();
const faOptions = ref<{
  value: any;
  label: string;
}[]>([]);

// 可管理服务商，则是管理员
const canAdminFacilitator = computed(() => baseOperateStore.operates?.find((item) => item.key === 'ADMIN_STORE_FACILITATOR'));

// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(async() => {
  const res = await useApi().deviceApi.listDeviceTypeDevice();
  const options = res.data.data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));

  if (canAdminFacilitator.value) {
    const faRes = await useApi().facilitatorApi.listFacilitatorfacilitator({ conditions: {} });
    faOptions.value = faRes.data.data?.map((item: any) => ({
      value: item.id,
      label: item.name,
    })) || [];
  }


  columns.value = [
    ...(
      canAdminFacilitator.value ? [
        {
          name: 'remark',
          label: '设备备注',
          type: 'string',
          isCondition: true,
          hideEdit: true,
          hideCreate: true,
          order: 10,
        },
      ] : []
    ) as any,

    {
      name: 'deviceIdHex',
      label: '设备序列号',
      type: 'string',
      rule: [{ required: true }],
    },
    ...(
      canAdminFacilitator.value ? [
        {
          name: 'deviceTypeId',
          label: '设备类型',
          type: 'select',
          options,
          rule: [{ required: true }],
          isCondition: true,
          hideEdit: true,
          hideCreate: true,
          render: ({ row: { deviceTypeId } }: any) => {
            const deviceType = options.find((item: any) => item.value === deviceTypeId);
            if (!deviceType) {
              return '-';
            }
            return deviceType.label;
          },
        },
      ] : []
    ) as any,

    {
      name: 'name',
      label: '店铺名称',
      type: 'string',
      rule: [{ required: true }],
    },
    {
      name: 'phone',
      label: '店主手机号',
      type: 'numberStr',
      rule: [{ required: true }],
      isCondition: true,
    },
    {
      name: 'rangeTime',
      label: '开门时间',
      type: 'string',
      width: 120,
      render: ({ row: { start, end } }) => `${todaySecFormat(start)} - ${todaySecFormat(end)}`,
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'startTime',
      label: '开门时间（开始）',
      type: 'timepick',
      hideTable: true,
      rule: [{ required: true }],
    },
    {
      name: 'endTime',
      label: '开门时间（结束）',
      type: 'timepick',
      hideTable: true,
      rule: [{ required: true }],
    },
    {
      name: 'geo',
      label: '定位',
      type: 'string',
      width: 120,
      render: ({ row: { longtitude, latitude } }) => (longtitude ? `${longtitude}, ${latitude}` : '未定位（仅支持一店一码）'),
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'longtitude',
      label: '定位（经度）',
      type: 'string',
      hideTable: true,
    },
    {
      name: 'latitude',
      label: '定位（纬度）',
      type: 'string',
      hideTable: true,
    },
    {
      name: 'distance',
      label: '开门距离',
      type: 'number',
      min: 0,
      render: ({ row: { distance } }) => (distance ? `${distance}米` : ''),
    },
    ...(
      canAdminFacilitator.value ? [
        {
          name: 'facilitatorName',
          label: '归属服务商',
          type: 'string',
          hideCreate: true,
          hideEdit: true,
          rule: [{ required: true }],
          isCondition: true,
        },
        {
          name: 'facilitatorId',
          label: '归属服务商',
          type: 'searchSelect',
          options: faOptions.value,
          hideTable: true,
          render: ({ row: { facilitatorId } }: any) => {
            const facilitator = faOptions.value.find((item: any) => item.value === facilitatorId);
            if (!facilitator) {
              return '';
            }
            return facilitator.label;
          },
        },
      ] : []
    ) as any,
    {
      name: 'status',
      label: '设备状态',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
  ];
});

const operates = [

  {
    key: 'opendoor',
    name: '远程开门',
    handler: useBaseAsync(async(row: any) => {
      await useApi().deviceApi.deviceAdminOpendoorHexDevice(row.deviceIdHex);
      ElMessage.success('成功');
      tableRef.value?.refresh();
    }),
  },
  ...(
      canAdminFacilitator.value ? [
        {
          key: 'bindwx',
          name: '绑定公众号',
          handler: useBaseAsync(async(row: any) => {
            currentRow.value = row;
            try {
              loading.value = true;
              const wxsRes = await useApi().wxApi.listWxwx({ conditions: {} });
              const wxsOptions = wxsRes.data?.data?.map((item: any) => ({
                label: item.name,
                value: item.appid,
              })) || [];
              bindWxItems.value = [
                {
                  name: 'wxAppid',
                  label: '公众号',
                  type: 'select',
                  rule: [{ required: true }],
                  options: wxsOptions,
                },
              ];
            } finally {
              loading.value = false;
            }


            bindWxFormRef.value?.setOneFormState('wxAppid', row.wxAppid);
            bindWxFormRef.value?.showDialog();
          }),
        },
      ] : []
    ) as any,

  {
    key: 'getQrcode',
    name: '获取一店一码',
    handler: useBaseAsync(async(row: any) => {
      if (!row.wxAppid) {
        ElMessage.warning('请先绑定公众号！');
        return;
      }
      try {
        loading.value = true;
        await qrcodeRef.value?.show(row);
      } finally {
        loading.value = false;
      }
    }),
  },

];


// eslint-disable-next-line max-lines-per-function
const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().storeApi.pageStorestore({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };
    tableData.data = tableData.data.map((item) => {
      const statusRef = ref('加载中...');

      useApi()
        .deviceApi.deviceStatusHexDevice(item.deviceIdHex, {
          headers: {
            'custom-error': 'true',
          },
        })
        .then((statusRes) => {
          statusRef.value = statusRes.data.data ? '在线' : '离线';
        })
        .catch(() => {
          statusRef.value = '-';
        });

      return {
        ...item,
        status: statusRef,
        startTime: todaySecToDayjs(item.start).toDate(),
        endTime: todaySecToDayjs(item.end).toDate(),
      };
    });
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;


const addFun = useBaseAsync(async(data: any) => {
  data.start = getTodaySec(dayjs(data.startTime));
  data.end = getTodaySec(dayjs(data.endTime));
  if (data.facilitatorId) {
    data.facilitatorName = faOptions.value.find((item: any) => item.value === data.facilitatorId)?.label;
  } else {
    data.facilitatorId = undefined;
    data.facilitatorName = undefined;
  }
  await useApi().storeApi.addStorestore({
    storeAdminVo: data,
  });
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  data.start = getTodaySec(dayjs(data.startTime));
  data.end = getTodaySec(dayjs(data.endTime));
  if (data.facilitatorId) {
    data.facilitatorName = faOptions.value.find((item: any) => item.value === data.facilitatorId)?.label;
  } else {
    data.facilitatorId = undefined;
    data.facilitatorName = undefined;
  }

  await useApi().storeApi.updateStorestore({
    storeAdminVo: data,
  });
  ElMessage.success('修改成功');
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().storeApi.delStorestore(data.id);
  ElMessage.success('删除成功');
});

const handleBindWxOk = useBaseAsync(async(data: any) => {
  await useApi().storeApi.bindWxstore({
    storeId: currentRow.value.id,
    appid: data.wxAppid,
  });
  ElMessage.success('绑定成功');
  tableRef.value?.refresh();
});


init();

</script>
<style lang="scss" scoped>
</style>

import { defineComponent, h, ref } from 'vue';
import Toast from '@/components/Toast.vue';
import { debounce } from 'lodash';

export const useToast = () => {
  const visible = ref(false);
  const title = ref('');

  const close = debounce(() => {
    visible.value = false;
    title.value = '';
  }, 1000);

  return {
    cnt: defineComponent({
      render() {
        return h(Toast as any, { visible: visible.value, title: title.value });
      },
    }),
    show: (_title?: string) => {
      title.value = _title || '';
      visible.value = true;
      close();
    },
  };
};

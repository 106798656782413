<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="微信公众号管理"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :on-delete="deleteFun"
      :on-edit="updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :top-operates="topOperates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
  </div>
  <el-dialog v-model="visibleAuthQr" title="公众号管理员扫码授权" :width="320">
    <div class="qr" v-loading="qrloading">
      <QrcodeVue
        :value="qrUrl"
        :size="800"
        style="width: 200px; height: 200px"
      />
      <div class="qr-time">
        过期时间：{{ qrExpiresAt }}
      </div>
      <el-button type="primary" @click="() => { visibleAuthQr = false; tableRef?.refresh(); }">已授权完成</el-button>
    </div>
  </el-dialog>
  <relate @set-primary="handleSetPrimary" ref="relateRef" />
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  useRoute,
  useRouter,
  useBaseTenantStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import dayjs from 'dayjs';
import { getCurrentRootUrl, getCurrentUrl, todaySecToDayjs } from '@/utils';
import QrcodeVue from 'qrcode.vue';
import relate from './relate.vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const relateRef = ref<InstanceType<typeof relate>>();
const baseTenantStore = useBaseTenantStore();
const addInitValue = {

};
const conditionsInitValue = {
};


const init = useBaseAsync(async() => {
  const res = await useApi().weappApi.listWeappweapp({
    conditions: {},
  });
  const options = res?.data?.data.map((item: any) => ({
    label: item.name,
    value: item.appid,
  })) || [];


  columns.value = [

    {
      name: 'remark',
      label: '备注',
      type: 'string',
      isCondition: true,
    },
    {
      name: 'name',
      label: '公众号名称',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
      hideEdit: true,
    },
    {
      name: 'appid',
      label: 'AppID',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
      hideEdit: true,
    },
    {
      name: 'principalName',
      label: '主体名称',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
      hideEdit: true,
    },
    {
      name: 'openMsgTemplateId',
      label: '开门模板消息(47550)id',
      type: 'string',
      width: 160,
    },
    {
      name: 'applyTemplateId',
      label: '门禁申请模板消息(58650)id',
      type: 'string',
      width: 160,
    },
    {
      name: 'weappAppid',
      label: '主小程序',
      type: 'select',
      options,
      hideCreate: true,
      hideEdit: true,

      width: 160,
    },
    {
      name: 'createTime',
      label: '授权时间',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { createTime } }: any) => dayjs(createTime).format('YYYY-MM-DD HH:mm:ss'),
    },

  ];
});

const qrUrl = ref('');
const qrExpiresAt = ref('');
const visibleAuthQr = ref(false);
const qrloading = ref(false);

const topOperates: any[] = [
  {
    key: 'jumpAdd',
    name: '授权微信公众号',
    handler: async() => {
      visibleAuthQr.value = true;
      qrloading.value = true;
      try {
        const res = await useApi().wxApi.getWxAuthUrlwx({
          redirectUri: encodeURIComponent(`${getCurrentRootUrl()}${
            baseTenantStore.tenantInfo?.id ? `/___tenant_${baseTenantStore.tenantInfo?.id}` : ''
          }/auth?type=1`),
        });
        qrUrl.value = res.data.data?.url;
        qrExpiresAt.value = res.data.data?.expiresAt;
      } finally {
        qrloading.value = false;
      }
    },
  },
];

const currentRow = ref<any>();

const operates: any[] = [

  {
    key: 'relateWeapp',
    name: '关联小程序',
    handler: (row: any) => {
      console.log(row);
      currentRow.value = row;
      relateRef.value?.show(row.appid);
    },
  },
];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().wxApi.pageWxwx({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;


const updateFun = useBaseAsync(async(data: any) => {
  await useApi().wxApi.updateWxwx({
    wxAdminVo: data,
  });
  ElMessage.success('修改成功');
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().wxApi.delWxwx(data.appid);
  ElMessage.success('删除成功');
});
const handleSetPrimary = useBaseAsync(async(_appid: string) => {
  await useApi().wxApi.updateWxwx({
    wxAdminVo: {
      ...currentRow.value,
      weappAppid: _appid,
    },
  });
  ElMessage.success('设置成功');
  tableRef.value?.refresh();
});


init();


</script>
<style lang="scss" scoped>
.qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;

  &-time {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>

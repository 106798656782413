<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="服务商"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-delete="deleteFun"
      :on-edit="updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import dayjs from 'dayjs';
const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();


const addInitValue = {

};
const conditionsInitValue = {
};


let adminOptions = ref<{
  value: any;
  label: string;
}[]>([]);
// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(async() => {
  const res = await useApi().adminApi.listAdminadmin({ conditions: {} });
  console.log(res);
  adminOptions.value
    = res.data.data?.map((item: any) => ({
      value: item.id,
      label: `${item.name}`,
    })) || [];
  columns.value = [
    {
      name: 'name',
      label: '服务商姓名',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'phone',
      label: '服务商电话',
      type: 'numberStr',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'password',
      label: '登录密码',
      type: 'password',
      rule: [{ required: true }],
    },
    {
      name: 'province',
      label: '服务省份',
      type: 'string',
      rule: [{ required: true }],
    },
    {
      name: 'city',
      label: '服务市',
      type: 'string',
      rule: [{ required: true }],
    },
    {
      name: 'createTime',
      label: '加入时间',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { createTime } }: any) => dayjs(createTime).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      name: 'deviceCount',
      label: '铺设设备',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'relativeId',
      label: '跟进人员',
      type: 'searchSelect',
      options: adminOptions.value,
      hideTable: true,
    },
    {
      name: 'relativeName',
      label: '跟进人员',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },

  ];
});

const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().facilitatorApi.pageFacilitatorfacilitator({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.idHex})操作，请谨慎操作`;


const addFun = useBaseAsync(async(data: any) => {
  if (data.relativeId) {
    data.relativeName = adminOptions.value.find((item: any) => item.value === data.relativeId)?.label;
  }
  await useApi().facilitatorApi.addFacilitatorfacilitator({
    facilitatorAdminVo: data,
  });
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  if (data.relativeId) {
    data.relativeName = adminOptions.value.find((item: any) => item.value === data.relativeId)?.label;
  }
  await useApi().facilitatorApi.updateFacilitatorfacilitator({
    facilitatorAdminVo: data,
  });
  ElMessage.success('修改成功');
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().facilitatorApi.delFacilitatorfacilitator(data.id);
  ElMessage.success('删除成功');
});


init();

</script>
<style lang="scss" scoped>
</style>

<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="小程序模板"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :on-add="baseOperateStore.operates?.find(item => item.key === 'ADD_WEAPP_TPL') && addFun"
      :on-delete="baseOperateStore.operates?.find(item => item.key === 'DEL_WEAPP_TPL') && deleteFun"
      :on-edit="baseOperateStore.operates?.find(item => item.key === 'EDIT_WEAPP_TPL') && updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
    <tpldraft ref="tpldraftRef" />
    <tpl ref="tplRef" @set-tpl="handleSetTpl" />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  useBaseOperateStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import dayjs from 'dayjs';
import tpldraft from './tpldraft.vue';
import tpl from './tpl.vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const tpldraftRef = ref<InstanceType<typeof tpldraft>>();
const tplRef = ref<InstanceType<typeof tpl>>();

const baseOperateStore = useBaseOperateStore();

const addInitValue = {

};
const conditionsInitValue = {
};


const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'name',
      label: '模板名称',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'templateId',
      label: '模板ID',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'currentVersion',
      label: '当前版本',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'description',
      label: '版本描述',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'extjsFormItems',
      label: 'extjs配置',
      type: 'textaera',
    },

    {
      name: 'createTime',
      label: '创建时间',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { createTime } }: any) => dayjs(createTime).format('YYYY-MM-DD HH:mm:ss'),
    },

  ];
});

const currentWeappTpl = ref<any>();

const operates: any[] = baseOperateStore.operates?.find((item) => item.key === 'ADMIN_OPEN_WEAPP_TPL') ? [

  {
    key: 'tpldraft',
    name: '模板草稿',
    handler: useBaseAsync((row: any) => {
      tpldraftRef.value?.show();
    }),
  },

  {
    key: 'tpllist',
    name: '模板列表',
    handler: useBaseAsync((row: any) => {
      currentWeappTpl.value = row;
      tplRef.value?.show();
    }),
  },
] : [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().weappTplApi.pageWeappTplweappTpl({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data?.map((item: any) => ({
        ...item,
        extjsFormItems: JSON.stringify(item.extjsFormItems),
      })) || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;


const addFun = useBaseAsync(async(_data: any) => {
  const data = { ..._data };
  if (data.extjsFormItems) {
    try {
      data.extjsFormItems = JSON.parse(data.extjsFormItems);
    } catch (error) {
      console.log(error);
      data.extjsFormItems = undefined;
    }
  }
  await useApi().weappTplApi.addWeappTplweappTpl(data);
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(_data: any) => {
  const data = { ..._data };
  if (data.extjsFormItems) {
    try {
      data.extjsFormItems = JSON.parse(data.extjsFormItems);
    } catch (error) {
      console.log(error);
      data.extjsFormItems = undefined;
    }
  }
  await useApi().weappTplApi.updateWeappTplweappTpl(data);
  ElMessage.success('修改成功');
});

const handleSetTpl = useBaseAsync(async(data: any) => {
  if (!currentWeappTpl.value) {
    return;
  }
  await useApi().weappTplApi.updateWeappTplweappTpl({
    ...currentWeappTpl.value,
    currentVersion: data.currentVersion,
    description: data.description,
    templateId: data.templateId,
  });
  ElMessage.success('设置成功');
  tableRef.value?.refresh();
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().weappTplApi.delWeappTplweappTpl(data.id);
  ElMessage.success('删除成功');
});


init();

</script>
<style lang="scss" scoped>
</style>

import { defineComponent, h, ref } from 'vue';
import Loading from '@/components/Loading.vue';

export const useLoading = () => {
  const visible = ref(false);
  const title = ref('');
  return {
    cnt: defineComponent({
      render() {
        return h(Loading as any, { visible: visible.value, title: title.value });
      },
    }),
    show: (_title?: string) => {
      title.value = _title || '';
      visible.value = true;
      console.log(123);
    },
    hide: () => {
      visible.value = false;
      title.value = '';
    },
  };
};
